.container {
	width: 70%;
	margin: auto;
	padding-top: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form {
	width: 100%;
	margin: 50px auto;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
}
.title {
	font-size: 28px;
	font-weight: bold;
}
.photo__main {
	max-width: 400px;
	height: auto;
	object-fit: contain;
	margin-top: 20px;
}

.gallery {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.gallery__cards {
	display: flex;
	flex-direction: row;
	margin-top: 15px;
	gap: 25px;
	margin-bottom: 30px;
}

.gallery__image {
	max-width: 200px;
	height: auto;
	object-fit: contain;
	margin: auto;
	padding-top: 15px;
}

.gallery__title {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
}

@media (max-width: 992px) {
	.gallery__image {
		max-width: 150px;
	}
}

@media (max-width: 768px) {
	.container {
		width: 90%;
	}
	.gallery__image {
		max-width: 100px;
	}
}

@media (max-width: 576px) {
	.gallery__cards {
		flex-direction: column;
		margin-bottom: 20px;
	}
	.photo__main {
		max-width: 90%;
	}

	.gallery__image {
		max-width: 90%;
	}

	.title {
		font-size: 22px;
	}
}
