.conterButton {
	box-shadow: 5px 5px 15px rgb(203 197 197);
	border: none;
	border-radius: 5px;
	padding: 5px;
	background-color: white;
	margin: 10px;
}

.conterButton:disabled {
	opacity: 0.4;
	pointer-events: none;
}
