.container {
	width: 100%;
	height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 50px;
	padding-bottom: 100px;
}

.form {
	width: 50%;
	display: flex;
	flex-direction: column;

	gap: 30px;
}

.title {
	text-align: center;
	font-size: 34px;
	font-weight: bold;
	margin: 60px;
	color: var(--default-deep-red);
}

.singupGroup {
	margin: 30px auto 0 auto;
	display: flex;
	flex-direction: row;
	gap: 30px;
}
.singupGroup:first-of-type {
	margin-top: 60px;
}

.link {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	color: var(--default-deep-red);
	text-decoration: none;
}

.text {
	font-size: 20px;
}

.error {
	font-size: 18px;
	font-weight: bold;
	color: red;
	text-align: center;
	margin-top: 15px;
}

@media (max-width: 768px) {
	.form {
		width: 80%;
	}

	.link {
		font-size: 18px;
	}

	.text {
		font-size: 18px;
	}
}

@media (max-width: 576px) {
	.container {
		padding-top: 35px;
	}

	.title {
		margin: 40px;
		font-size: 28px;
	}
	.link {
		font-size: 16px;
	}

	.text {
		font-size: 16px;
	}

	.singupGroup {
		flex-direction: column;
		gap: 5px;
	}
}
