.main {
	width: 80%;
	margin: auto;
	padding-top: 70px;
	padding-bottom: 50px;
	min-height: 100vh;
}

.container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 30px;
}

.tabs {
	display: flex;
	flex-direction: row;
	list-style: none;
	gap: 50px;
	padding-left: 0;
}

.tab {
	font-family: 'MarckScript', sans-serif;
	font-size: 26px;
	font-style: italic;
	cursor: pointer;
	margin: 0;
	padding-bottom: 20px;
	width: 100%;
	align-items: center;
	text-align: center;
}

.tab:hover {
	transition: all 0.3s linear;
	text-shadow: 3px 3px 3px rgb(172, 156, 156);
}

.tab_active {
	font-weight: bold;
}

.wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 50px;
}
.scroll {
	width: 300px;
	max-height: 50vh;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: white;
	border-radius: 15px;
	position: sticky;
	top: 250px;
	box-shadow: 0 0 25px 30px white;
}
.scroll::-webkit-scrollbar {
	width: 5px;
}

.scroll::-webkit-scrollbar-track {
	margin-top: 5px;
	background: white;
}

.scroll::-webkit-scrollbar-thumb {
	background: rgb(239, 223, 223);
	height: 300px;
	border-radius: 5px;
}

.list {
	padding: 0;
	list-style: none;
}
.list__item {
	height: 40px;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	padding: 8px 12px 5px 12px;
	font-size: 18px;
	font-weight: normal;
	border-bottom: 1px solid rgb(202, 191, 191);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.list__item:hover {
	background-color: rgb(239, 223, 223);
	transition: all 0.3s linear;
	scale: 1.02;
}

@media (max-width: 768px) {
	.main {
		width: 95%;
	}
	.scroll {
		width: 30%;
	}
	.list__item {
		font-size: 14px;
		padding: 7;
	}
}
@media (max-width: 576px) {
	.main {
		padding-top: 50px;
	}
	.container {
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	}
	.wrapper {
		gap: 25px;
		justify-content: space-around;
	}

	.tabs {
		flex-direction: column;
	}

	.tabs:last-of-type {
		padding-bottom: 10px;
		gap: 10px;
	}

	.tab {
		padding-bottom: 0;
		font-size: 22px;
	}
}

@media (max-width: 512px) {
	.scroll {
		margin-top: 10px;
		width: 70%;
	}
}
