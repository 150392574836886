.input + label {
	display: flex;
	flex-direction: row;
	gap: 30px;
	width: fit-content;
	height: 40px;
	align-items: center;
}

.input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.input + label::before {
	content: '';
	display: inline-block;
	width: 2em;
	height: 2em;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid rgb(209, 184, 184);
	border-radius: 0.25em;
	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	background-color: white;
}

.input:checked + label::before {
	border-color: var(--default-deep-red);
	background-color: var(--default-deep-red);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
