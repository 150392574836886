.container {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 70%;
	max-width: 1200px;
	margin: auto;
	padding-top: 50px;
	padding-bottom: 50px;
	min-height: 100vh;
}

.cartItemGroup {
	box-shadow: 5px 5px 15px rgb(203 197 197);
	padding: 20px;
	border-radius: 20px;
	background-color: white;
}

.title {
	font-size: 24px;
	align-self: center;
	font-weight: bold;
	padding-top: 100px;
}
.total {
	display: flex;
	justify-content: end;
	background-color: rgb(249 235 235);
	box-shadow: 5px 5px 15px rgb(203 197 197);
	border-radius: 20px;
	padding-right: 15px;
}

.text {
	color: var(--default-deep-red);
	font-weight: bold;
	font-size: 24px;
	margin: 10px;
}
@media (max-width: 992px) {
	.container {
		width: 90%;
	}
}
@media (max-width: 768px) {
	.cartItemGroup {
		flex-direction: column;
	}
}

@media (max-width: 576px) {
	.text {
		font-size: 14px;
	}
	.total {
		padding-left: 0;
		justify-content: center;
	}
}
