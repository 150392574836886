.icon {
	cursor: pointer;
	position: relative;
}

.counter {
	position: absolute;
	top: -5px;
	right: -10px;
	display: inline-block;

	font-size: 1rem;
	font-weight: bold;
	background-color: red;
	color: white;
	border-radius: 50%;
	width: 25px;
	line-height: 25px;
	text-align: center;
}

.fill {
	fill: rgb(97, 97, 104);
	transition: fill 0.3s linear;
}

.fill:hover {
	/* fill: rgb(23, 23, 24); */
	fill: red;
}
