@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}

	from {
		visibility: hidden;
	}
	to {
		visibility: visible;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}

	from {
		visibility: visible;
	}
	to {
		visibility: hidden;
	}
}

.fadeIn {
	animation: fadeIn 0.7s linear;
}

.fadeOut {
	animation: fadeOut 0.7s linear;
}

.overlay {
	display: flex;
	position: fixed;
	overflow: auto;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 999;
	justify-content: center;
	align-items: center;
}

.closeButton {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 999;
}
