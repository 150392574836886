.icon {
	cursor: pointer;
}

.fill {
	fill: rgb(97, 97, 104);
	transition: fill 0.3s linear;
}

.fill:hover {
	fill: rgb(23, 23, 24);
}
