@import './styles/vendor/normalize.scss';
@import './styles/vendor/fonts/fonts.scss';
body {
	margin: 0;
	padding: 0;
	background-color: white;
	font-family: -apple-system, BlinkMacSystemFont, 'IBM Plex Sans', 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--default-deep-red: #ab1f1f;
	--defaut-deep-pink: rgb(249, 187, 187);
}
.box-flex-row {
	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
}

.box-flex-column {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
}

/* text */
.text-default {
	font-size: 1rem;
	font-weight: 400;
	margin: 0;
}

.text-small {
	font-size: 0.8rem;
	font-weight: 400;
	margin: 0;
}

.text-medium {
	font-size: 20px;
	font-weight: 400;
	margin: 0;
}

.text-large {
	font-size: 2rem;
	font-weight: 400;
	margin: 0;
}

.text-regular {
	font-weight: 400;
	margin: 0;
}
.text-bold {
	font-weight: bold;
	margin: 0;
}
