.container {
	width: 100%;
	height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form {
	width: 40%;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.title {
	text-align: center;
	font-size: 32px;
	font-weight: bold;
	margin: 30px;
	color: var(--default-deep-red);
}

.subtitle {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	color: var(--default-deep-red);
}

.error {
	font-size: 18px;
	font-weight: bold;
	color: red;
	text-align: center;
}
@media (max-width: 768px) {
	.form {
		width: 80%;
	}
}

@media (max-width: 576px) {
	.container {
		padding-top: 35px;
	}

	.title {
		margin: 40px auto;
		font-size: 24px;
	}
}
