.container {
	height: 100vh;
	width: 100%;
	margin: auto;
}

.message {
	text-align: center;
	padding-top: 30vh;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
}
