.list {
	list-style: none;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 100px;
	right: 90px;
	box-shadow: 4px 4px 18px 8px rgba(135, 134, 134, 0.22);
	border-radius: 10px;
	width: 270px;
	transition: all 0.2s linear;
}

.list_default {
	transform: scaleY(0);
	transform-origin: 0 0;
}
.list_active {
	transform: scaleY(1);
}

.list__item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid rgb(202, 191, 191);
	padding: 10px 35px 10px 20px;
	background-color: white;
	height: 60px;
	box-sizing: border-box;
}
.list__item:first-of-type {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.list__item:last-of-type {
	border-bottom: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.list__item:nth-child(4) {
	font-weight: bold;
	cursor: auto;
}

.list__item:hover {
	transition: background-color 0.3s linear;
	background-color: rgb(239, 223, 223);
}

.exit:hover {
	cursor: pointer;
}
.link {
	text-decoration: none;
	color: black;
	margin: 0;
	width: 100%;
}

.link_active {
	text-decoration: none;
	color: black;
	font-weight: bold;
	margin: 0;
	width: 100%;
}

.wrapper {
	justify-content: space-between;
}

@media (max-width: 414px) {
	.list {
		width: 100%;
		right: 0;
		top: 115px;
	}
}
