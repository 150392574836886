.container {
	position: relative;
	width: 100%;
	height: 100%;
}
.input {
	display: block;
	box-sizing: border-box;
	height: 38px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 100%;
	font-size: 16px;
	padding: 10px 35px 10px 10px;
	outline: none;
	height: 150px;
	line-height: 1.4;
}

.input:focus {
	box-shadow: 5px 5px 10px 1px rgba(154, 148, 148, 0.3);
}

.label {
	width: 100%;
	position: absolute;
	top: -20px;
	left: 0;
}

.error {
	color: red;
	position: absolute;
	font-weight: bold;
	bottom: -17px;
	left: 0;
}
