.container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
}

.text {
	color: var(--default-deep-red);
	margin-top: 30vh;
	font-size: 32px;
	font-weight: bold;
}

@media (max-width: 768px) {
	.text {
		font-size: 24px;
		text-align: center;
	}
}
