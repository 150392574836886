.container {
	min-height: 150px;
	min-width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(../../images/background.jpg);
	background-size: 60%;
	box-shadow: 0 0 25px 20px #e9d2d2;
	border-radius: 20px;
}

.text {
	font-size: 28px;
	font-weight: bold;
	color: var(--default-deep-red);
}

@media (max-width: 768px) {
	.container {
		min-width: 90%;
	}

	.text {
		font-size: 24px;
	}
}
